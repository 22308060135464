// import axios from "axios";
import {Route, Routes} from "react-router-dom";
import PlaceHome from "./PlaceHome/PlaceHome";
import Page404 from "../../containers/Page404/Page404";
import React from "react";
import useSWR from "swr";
import {Loader} from "../../components/Loader";

const API_URL = process.env.REACT_APP_API_URL;

const fetchPolicy = {
  onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
    // Never retry on 404.
    if (error.status === 404) return

    // Only retry up to 10 times.
    if (retryCount >= 3) return

    // Retry after 5 seconds.
    setTimeout(() => revalidate({ retryCount }), 5000)
  }
}

const fetcher = async url => {
  const res = await fetch(url)

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  console.log("Result", res.ok, res)
  if (!res.ok) {
    throw {
      info: await res.json(),
      status: res.status
    }
  }

  return res.json()
}

export function TenantPage({tenantID}) {
  const { data, error, isLoading } = useSWR(`${API_URL}/public/tenant/${tenantID}`, fetcher, fetchPolicy);

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    if (error.status === 404) {
      return <Page404/>
    } else {
      return <>Error: {error.info}</>
    }
  }

  return <>
    <Routes>
      <Route path="/" element={<PlaceHome placeName={"Tenant" + tenantID}/>}/>
      <Route path="*" element={<Page404/>}/>
    </Routes>
  </>;
}