import React from "react";
import DomainAwareRouter from "routers/index";

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <DomainAwareRouter />
    </div>
  );
}

export default App;
